import React from "react";

export const Experience = (props) => {
  return (
    <div id="experience" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Experiencia de nuestro equipo</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div
                  key={`${d.title}-${i}`}
                  className={`col-md-4 col-xs-6 ${
                    props.data.length % 2 !== 0 && i === props.data.length - 1
                      ? "mobile-offset"
                      : ""
                  }`}
                >
                  <i className={d.icon}></i>
                  <h3>{d.title}</h3>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
