import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export const Header = (props) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <header id="carousel">
      {props.data && props.data.slides && props.data.slides.length > 0 ? (
        <Carousel
          showArrows={true}
          infinite={true}
          showThumbs={false}
          showStatus={false}
          autoPlay={true}
          autoPlaySpeed={6000}
          responsive={responsive}
        >
          {props.data.slides.map((slide, index) => (
            <div key={index} className="carousel-slide">
              <img src={slide.imageSrc} alt={slide.title} />
              <div className="text-container">
                <h3>{slide.title}</h3>
                <p>{slide.paragraph}</p>
              </div>
            </div>
          ))}
        </Carousel>
      ) : (
        <div className="placeholder">
          <p>No hay imágenes disponibles</p>
        </div>
      )}
    </header>
  );
};
