import React from "react";

export const Team = (props) => {
  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Socios</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4 col-sm-12 team">
                  <div className="thumbnail">
                    <div className="img-container">
                      <img src={d.img} alt="..." className="team-img" />
                    </div>
                    <div className="caption">
                      <h4>
                        {d.name}
                        <a
                          href={d.linkedin}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="linkedin-icon"
                        >
                          <i className="fa fa-linkedin"></i>
                        </a>
                      </h4>
                      <h5>{d.job}</h5>
                      <h6>{d.exjob}</h6>
                      <p>{d.testim}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
